import { useEffect } from "react";
import { useAnimation, motion } from "framer-motion";
import { useInView } from "react-intersection-observer";
import Underline from "./underline";
import AnimatedFade from "./AnimatedFade";

type AnimatedBodyProps = {
  text: string;
  className?: string;
  delay?: number;
    underline?: boolean;
    underlineColor?: string;
};

export default function AnimatedBody({
    text,
    className,
    delay,
    underline = false,
    underlineColor = "#E3C37A",
}: AnimatedBodyProps) {
    const ctrls = useAnimation();

    const { ref, inView } = useInView({
        threshold: 0.1,
        triggerOnce: false,
    });

    useEffect(() => {
        if (inView) {
            ctrls.start("visible");
        }
        if (!inView) {
            ctrls.start("hidden");
        }
    }, [ctrls, inView]);

    const bodyAnimation = {
        hidden: {
            opacity: 0,
            y: `1em`,
        },
        visible: {
            opacity: 1,
            y: `0em`,
            transition: {
                delay: delay,
                duration: 1,
                ease: [0.2, 0.65, 0.3, 0.9],
            },
        },
    };

    return (
        <motion.span
            aria-label={text}
            role="heading"
            className={`${className}  z-50`}
            ref={ref}
            aria-hidden="true"
            style={{
                position: "relative",
            }}
            initial="hidden"
            animate={ctrls}
            variants={bodyAnimation}
        >
           <span className="z-40 relative"> {text}</span>
            {underline && (

                <AnimatedFade >
<span className="h-[100%] w-full z-0 absolute bottom-0 flex flex-end items-end justify-end  left-0 bg-">

    <Underline fill={underlineColor}  />
</span>
</AnimatedFade>
)}
        </motion.span>
    );
}
