import { useEffect } from "react";
import { useAnimation, motion } from "framer-motion";
import { useInView } from "react-intersection-observer";

type AnimatedFadeProps = {
  className?: string;
  delay?: number;
    children: React.ReactNode;
};

export default function AnimatedFade({
    className,
    children
}: AnimatedFadeProps) {
    const ctrls = useAnimation();

    const { ref, inView } = useInView({
        threshold: 0.01,
        triggerOnce: false,
    });

    useEffect(() => {
        if (inView) {
            ctrls.start("visible");
        }
        if (!inView) {
            ctrls.start("hidden");
        }
    }, [ctrls, inView]);

    const bodyAnimation = {
        hidden: {
            opacity: 0,
            width : 0,
            transition : {
                delay : 0.5,
                duration : 0.4,
            }
        },
        visible: {
            opacity: 1,
width: '100%',
            transition: {
                delay: 0.50,
                duration: 0.4,
            },
        },
    };

    return (
        <motion.div
            role="heading"
            className={`${className}  `}
            ref={ref}
            aria-hidden="true"
            style={{
                position: "relative",
            }}
            initial="hidden"
            animate={ctrls}
            variants={bodyAnimation}
        >
          {children}
        </motion.div>
    );
}
