import { MotionValue,  useTransform } from "framer-motion";
import React from "react";
import Particless from "../components/other/particles";

import HeroText from "./Hero";

export function useParallax(value: MotionValue<number>, minDistance: number, maxDistance: number) {
    return useTransform(value, [0, 1], [minDistance, maxDistance]);
}// @ts-ignore

const Heroerror = ({certificate }: any) => {
  



return (
<section className="relative z-10 flex  h-screen w-full justify-center" id="home" 
    >


    <div className="mt-10 flex flex-col items-center justify-center sm:mt-0">
        <Particless show={'visible'} />
       <HeroText parallax={1.2} title1="Aradığın Sayfayı" title2="Bulamadık" desc="Sertifikana ulaşmak için,ürünle birlikte gönderilen kart üzerindeki qr kodu okutabilirsin" />

    </div>
</section>
);
};

export default Heroerror;