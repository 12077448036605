import { useEffect, useMemo, useState } from "react";
import Particles, { initParticlesEngine } from "@tsparticles/react";
import { type Container, type ISourceOptions } from "@tsparticles/engine";
// import { loadAll } from "@/tsparticles/all"; // if you are going to use `loadAll`, install the "@tsparticles/all" package too.
// import { loadFull } from "tsparticles"; // if you are going to use `loadFull`, install the "tsparticles" package too.
import { loadSlim } from "@tsparticles/slim"; // if you are going to use `loadSlim`, install the "@tsparticles/slim" package too.
// import { loadBasic } from "@tsparticles/basic"; // if you are going to use `loadBasic`, install the "@tsparticles/basic" package too.

export default function Particless({show = "visible"} : any ) {
  const [init, setInit] = useState(false);
 
  // this should be run only once per Particlesslication lifetime
  useEffect(() => {
    initParticlesEngine(async (engine) => {
     
      await loadSlim(engine);
      //await loadBasic(engine);
    }).then(() => {
      setInit(true);
    });
  }, []);

  const particlesLoaded = async (container?: Container): Promise<void> => {
    console.log(container);
  };

  const options: ISourceOptions = useMemo(
    () => ({
      background: {
        color: {
          value: "transparent",
        },
        // show only 1000px height
        
      
      },
      
      fpsLimit: 120,
   
      
      particles: {
        number: {
          value: 199,
          density: {
            enable: true,
            value_area: 473.51100518494565
          }
        },
        color: {
          value: ["#ffffff","#ffffff","#ffffff", "#ffd700"], // white and gold stars
        },
        shape: {
          type: "star",
          stroke: {
            width: 0,
            color: {
              value: ["#ffffff", "#ffd700"], // white and gold stars
            },          },
          polygon: {
            nb_sides: 5
          },
          image: {
            src: "img/github.svg",
            width: 100,
            height: 100
          }
        },
        opacity: {
          value: {
            min: 0,
            max: 1,
        },          random: true,
          animation: {
            enable: false,
            speed: 1,
            opacity_min: 0,
            sync: false
          }
        },
        size: {
          value: {
            min: 1,
            max: 3,
        },
                  random: true,
          animation: {
            enable: true,
            mode: "random",
            speed: 6.5,
            sync: true
          }
        },
        line_linked: {
          enable: false,
          distance: 150,
          color: "#ffffff",
          opacity: 0.4,
          width: 1
        },
        move: {
          enable: true,
          speed: 2.1,
          direction: "right",
          random: false,
          straight: false,
          out_mode: "bounce",
          bounce: false,
          attract: {
            enable: false,
            rotateX: 600,
            rotateY: 600
          }
        }
      },
    
    
      detectRetina: true,
    }),
    []
  );
  

  if (init) {
    return <Particles className={`${show}`} id="tsparticles" particlesLoaded={particlesLoaded} options={options} />;
  }

  return <></>;
};