import { motion, useScroll, useSpring, useTransform } from "framer-motion";
import React, { useRef } from "react";

import AnimatedBody from "@/animations/AnimatedBody";
import { Ivy } from "@/fonts/spaceGrotesk";

const HeroText = ({ title1, title2, desc }: { title1: string; title2: string; desc: string, parallax : number }) => {
   
    const ref = useRef(null)
    const { scrollYProgress } = useScroll({
      target: ref,
      offset: ["start end", "100vh"]
    })

// @ts-ignore
const customytransform = (progress : any) => {
        return (  progress*250  ) -125;
  };

 // @ts-ignore

  const customOpacity = (progress : any) => {   
    if (progress > 0.3) {
      return (2 - progress*2 );
    }
  };
  
  
  const y = useTransform(scrollYProgress, [0, 0.5, 1], [0, 10, 500]);
const opacity = useTransform(scrollYProgress, customOpacity);
 const scaledProgress = useTransform(scrollYProgress, [0, 1], [0, 2]);

 const scale = useSpring(scaledProgress, {
        stiffness: 100,
        damping: 30,
        restDelta: 0.001
    });
    
    
    return (
        <div             ref={ref}
        className={`relative w-screen !overflow-hidden h-screen flex flex-col items-center justify-center pointer-events-none`}>
            <motion.div  style={{
             scale,  y,opacity
            }} 
           
        
                className="mb-10  gap-5 w-screen text-[18px] font-bold text-center text-[#DDDACD] md:mb-16 flex flex-col  flex-center justify-center items-center md:text-[40px] leading-normal lg:mb-16 ">
               <AnimatedBody underline={true} delay={0.2} className="text-[3rem] leading-none sm:text-[5rem] font-black text-center m-0"
                    text={`${title1}`} />
                <AnimatedBody delay={0.2} className={`text-[3rem] -mt-3 leading-none pt-0 sm:text-[4rem] font-bold text-center m-0
                    ${Ivy.className}`} text={`${title2}`} />
                <AnimatedBody delay={0.2}
                    className="text-[0.8rem] w-screen sm:text-[1.2rem] font-normal text-center !text-opacity-100 !text-white-vibrant max-w-[450px]"
                    text={`${desc}`} />
            </motion.div>
        </div>
    );
};

export default HeroText;
