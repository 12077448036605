import Link from "next/link";
import { motion } from "framer-motion";
import AnimatedBody from "@/animations/AnimatedBody";
import React from "react";
import Image from "next/image";
import svgImage from "@/public/motio.svg";
import { FaFacebook, FaInstagram, FaXTwitter } from "react-icons/fa6";

const Footer = () => {
    return (
        <motion.section
            className=" h-[22vh] w-full  items-center justify-center bg-black z-[60] bg-opacity-100 pt-10  font-bold  md:h-[20vh] md:py-16 lg:h-[10vh] lg:pt-6 lg:pb-0"
            initial="initial"
            animate="animate"
        >
            <motion.div className="mx-auto flex w-[90%] flex-row items-center justify-between text-center text-[12px] text-[#e4ded7] sm:text-[12px] md:text-[14px] lg:max-w-[1440px] lg:text-[14px]">
   <div className="flex flex-row gap-4"> 
   
   <Image
   src={svgImage}
   alt="Motio Logo"
   width={50}
    height={50}
    className="w-10 h-10"
    />
   
   
      <div className="flex flex-col flex-start items-start gap-1.5 ">    <AnimatedBody
                    text={"2023 Motio Dijital Tasarım Ajansı.                    "}
                    className={"text-gray-400 m-0 p-0 text-xs font-normal"}
                />  <AnimatedBody
                text={"© İZGİ YAZILIM VE E-TİCARET LİMİTED ŞİRKETİ                    "}
                className={"m-0 p-0 text-gray-400 font-normal text-xs"}
            /></div>
            </div>
                <div className="flex flex-col sm:flex-row  sm:gap-1 md:gap-2">
                <Link
                   className="text-gray-400 flex flex-row gap-1.5 items-center justify-center transition-all hover:text-white-vibrant cursor-pointer"
                   href={"https://www.twitter.com/seningokyuzun"}
                     >
                 <FaXTwitter />        Twitter
                    </Link>

                    <Link
                   className="text-gray-400 flex flex-row gap-1.5 items-center justify-center transition-all hover:text-white-vibrant cursor-pointer"
                   href={"https://www.instagram.com/seningokyuzuncom"}
                     >
                 <FaInstagram />        Instagram
                    </Link>

                    <Link
                   className="text-gray-400 flex flex-row gap-1.5 items-center justify-center transition-all hover:text-white-vibrant cursor-pointer"
                   href={"https://www.facebook.com/seningokyuzun"}
                     >
                 <FaFacebook />        Facebook
                    </Link>


                </div>
            </motion.div>
        </motion.section>
    );
};

export default Footer;
